//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
//@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,600;1,700&display=swap');

* {
  border:0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  list-style-type: none;
}

:root {
  --panel-background-color: #FFF;
  --panel-subdued-background-color: #FAFBFE;
  --text-color: #2B3D7F;
  --text-subdued-color: #929CBE;
  --border-hard-color: #C9D8F1;
  --border-subdued-color: #E3EBF8;
  --panel-hover-color: #F0F4FD;
  --panel-shadow-color: #C9D8F199;

  --button-color-hover: #606D9F;
  --button-color: #2B3D7F;
  --button-text-color: white
}

html {
  font-family: Open Sans;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  font-weight: 400;
  background: #FAFBFE;
  color: var(--text-color);

}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


svg {
  font-size: inherit;
}

//body {
  //margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
//}

//code {
  //font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    //monospace;
//}
